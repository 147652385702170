<script setup lang="ts">
const appConfig = useAppConfig()

const {
  modules,
  $users,
  MAPS,
} = useModules()

const {
  $tasksOfUser,
  $notificationsOfUser,
  moduleId,
  coreModules,
  otherModules,
  activeModule,
  links,
  items,
  footerLinks,
  isModuleActive,
} = useNavigation(modules)

const groups = computed(() => [
  {
    key: 'links',
    label: 'Módulos',
    commands: links.value.flat().map(link => ({
      ...link,
      shortcuts: link.tooltip?.shortcuts,
    })),
  },
])
</script>

<template>
  <UDashboardLayout>
    <UDashboardPanel
      :width="250"
      :resizable="{ min: 250, max: 350, storage: 'local' }"
      collapsible
    >
      <UDashboardNavbar
        class="!border-transparent"
        :ui="{ left: 'flex-1 items-center' }"
      >
        <template #left>
          <UButton
            to="/"
            variant="ghost"
            square
            class="grid"
          >
            <UAvatar
              src="/favicon.svg"
              title="Inicio"
            />
          </UButton>
          <h2
            v-if="!isModuleActive"
            class="truncate text-gray-900 dark:text-white text-[16px] font-semibold"
          >
            {{ appConfig.title }}
          </h2>
          <ModulesDropdown v-else />
        </template>
      </UDashboardNavbar>

      <UDashboardSidebar>
        <template #header>
          <UDashboardSearchButton label="Buscar..." />
        </template>

        <template
          v-for="(linksGroup, i) in links"
          :key="`group-${i}`"
        >
          <UDashboardSidebarLinks
            :links="linksGroup"
            :ui="{
              active: 'before:bg-primary-100 dark:before:bg-primary-800',
              inactive: 'hover:before:bg-primary-50 dark:hover:before:bg-primary-800/50',
            }"
          />
          <UDivider v-if="i < links.length - 1" />
        </template>

        <div class="flex-1" />

        <UDashboardSidebarLinks
          :links="footerLinks"
          :ui="{
            active: 'before:bg-primary-100 dark:before:bg-primary-800',
            inactive: 'hover:before:bg-primary-50 dark:hover:before:bg-primary-800/50',
          }"
        />

        <UDivider class="sticky bottom-0" />

        <template #footer>
          <UserDropdown />
        </template>
      </UDashboardSidebar>
    </UDashboardPanel>

    <slot />

    <HelpSlideover />
    <NotificationsSlideoverList />

    <ClientOnly>
      <LazyUDashboardSearch
        :groups="groups"
        :empty-state="{ icon: 'i-mdi-magnify', label: 'No se encontraron resultados.', queryLabel: 'No se encontraron resultados para esa busqueda. Intentalo de nuevo.' }"
        placeholder="Buscar..."
      />
    </ClientOnly>
  </UDashboardLayout>
</template>
