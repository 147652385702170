<script setup lang="ts">
const { isOpenHelpSlideover } = useDashboard()
const { metaSymbol } = useShortcuts()

const shortcuts = ref(false)
const { query, isFiltering, clearFilters } = useViewQuery('')

const links = [
  {
    label: 'Atajos',
    color: 'gray',
    icon: 'i-mdi-keyboard',
    onClick: () => {
      shortcuts.value = true
    },
  },
  {
    label: 'Dirección',
    color: 'blue',
    icon: 'i-mdi-finance',
    to: '/docs/management',
  },
  {
    label: 'Tesoreria',
    color: 'green',
    icon: 'i-mdi-bank',
    to: '/docs/finance',
  },
  {
    label: 'Compras',
    color: 'red',
    icon: 'i-mdi-cart',
    to: '/docs/purchases',
  },
  {
    label: 'Ventas',
    color: 'purple',
    icon: 'i-mdi-tag',
    to: '/docs/sales',
  },
  {
    label: 'Inventarios',
    color: 'blue',
    icon: 'i-mdi-warehouse',
    to: '/docs/inventory',
    disabled: true,
  },
  {
    label: 'Producción',
    color: 'black',
    icon: 'i-mdi-factory',
    to: '/docs/production',
    disabled: true,
  },
  {
    label: 'Recursos Humanos',
    color: 'pink',
    icon: 'i-mdi-human',
    to: '/docs/hr',
    disabled: true,
  },
  {
    label: 'Administración',
    color: 'gray',
    icon: 'i-mdi-cog',
    to: '/docs/admin',
  },
]

// should match with defineShortcuts() in useDashboard()
const categories = computed(() => [
  {
    title: 'General',
    items: [
      { shortcuts: [metaSymbol.value, 'K'], name: 'Commandos' },
      // { shortcuts: ['T'], name: 'Tareas' },
      { shortcuts: ['N'], name: 'Notificaciones' },
      { shortcuts: ['?'], name: 'Ayuda' },
      { shortcuts: ['/'], name: 'Buscar' },
    ],
  },
  {
    title: 'Navegación',
    items: [
      { shortcuts: ['G', 'H'], name: 'Ir a Inicio' },
      { shortcuts: ['G', 'T'], name: 'Ir a Tareas' },
      { shortcuts: ['G', 'N'], name: 'Ir a Notificaciones' },
      { shortcuts: ['G', 'S'], name: 'Ir a Ajustes' },
    ],
  },
  {
    title: 'Notificaciones',
    items: [
      { shortcuts: ['↑'], name: 'Notificación anterior' },
      { shortcuts: ['↓'], name: 'Notificación siguiente' },
    ],
  },
])

const filteredCategories = computed(() => {
  return categories.value
    .map(category => ({
      title: category.title,
      items: category.items.filter((item) => {
        return item.name.search(new RegExp(query.value, 'i')) !== -1
      }),
    }))
    .filter(category => !!category.items.length)
})
</script>

<template>
  <UDashboardSlideover v-model="isOpenHelpSlideover">
    <template #title>
      <UButton
        v-if="shortcuts"
        color="gray"
        variant="ghost"
        size="sm"
        icon="i-mdi-arrow-left"
        @click="shortcuts = false"
      />

      {{ shortcuts ? 'Atajos' : 'Documentación' }}
    </template>

    <div
      v-if="shortcuts"
      class="space-y-6"
    >
      <UInput
        v-model="query"
        type="search"
        icon="i-mdi-magnify"
        placeholder="Buscar..."
        autofocus
        color="gray"
      />

      <div
        v-for="(category, index) in filteredCategories"
        :key="index"
      >
        <p class="mb-3 text-sm text-gray-900 dark:text-white font-semibold">
          {{ category.title }}
        </p>

        <div class="space-y-2">
          <div
            v-for="(item, i) in category.items"
            :key="i"
            class="flex items-center justify-between"
          >
            <span class="text-sm text-gray-500 dark:text-gray-400">{{ item.name }}</span>

            <div class="flex items-center justify-end flex-shrink-0 gap-0.5">
              <UKbd
                v-for="(shortcut, j) in item.shortcuts"
                :key="j"
              >
                {{ shortcut }}
              </UKbd>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else
      class="flex flex-col gap-y-3"
    >
      <UButton
        v-for="(link, index) in links"
        :key="index"
        v-bind="link"
        variant="soft"
      />
    </div>
  </UDashboardSlideover>
</template>
