<script setup lang="ts">
const { isOpenHelpSlideover } = useDashboard()
const { isDashboardSearchModalOpen } = useUIState()
const { metaSymbol } = useShortcuts()
const router = useRouter()

const { clear: signOut, user } = useUserSession()

const items = computed(() => [
  [
    {
      slot: 'account',
      label: '',
      disabled: true,
    },
  ],
  [
    {
      label: 'Ajustes',
      icon: 'i-mdi-cog',
      to: '/settings',
    },
    {
      label: 'Menú de comandos',
      icon: 'i-mdi-console',
      shortcuts: [metaSymbol.value, 'K'],
      click: () => {
        isDashboardSearchModalOpen.value = true
      },
    },
    {
      label: 'Ayuda y soporte',
      icon: 'i-mdi-help-circle',
      shortcuts: ['?'],
      click: () => {
        isOpenHelpSlideover.value = true
      },
    },
  ],
  [
    {
      label: 'Cerrar sesión',
      icon: 'i-mdi-logout',
      click: async () => {
        await signOut()
        router.push('/auth')
      },
    },
  ],
])
</script>

<template>
  <UDropdown
    mode="hover"
    :items="items"
    :ui="{ width: 'w-full', item: { disabled: 'cursor-text select-text' } }"
    :popper="{ strategy: 'absolute', placement: 'top' }"
    class="w-full"
  >
    <template #default="{ open }">
      <UButton
        color="gray"
        variant="ghost"
        class="w-full"
        :label="user.name"
        :class="[open && 'bg-gray-50 dark:bg-gray-800']"
      >
        <template #leading>
          <UAvatar
            :src="user.image"
            :alt="user.name"
            size="2xs"
          />
        </template>

        <template #trailing>
          <UIcon
            name="i-mdi-dots-horizontal"
            class="w-5 h-5 ml-auto"
          />
        </template>
      </UButton>
    </template>

    <template #account>
      <div class="text-left">
        <p>
          Sesión iniciada como
        </p>
        <p class="truncate font-medium text-gray-900 dark:text-white">
          {{ user.email }}
        </p>
      </div>
    </template>
  </UDropdown>
</template>
