<script setup lang="ts">
import { formatTimeAgo } from '@vueuse/core'

const { isOpenNotificationsSlideoverList } = useDashboard()

const {
  data: notifications,
  status,
  refresh,
} = await useFetch<Notification[]>('/db/notifications', { default: () => [] })
</script>

<template>
  <UDashboardSlideover
    v-model="isOpenNotificationsSlideoverList"
    title="Notifications"
  >
    <NuxtLink
      v-for="notification in notifications"
      :key="notification.id"
      :to="`/notifications?id=${notification.id}`"
      class="p-3 rounded-md hover:bg-gray-50 dark:hover:bg-gray-800/50 cursor-pointer flex items-center gap-3 relative"
    >
      <UChip
        color="red"
        :show="!!notification.unread"
        inset
      >
        <UAvatar
          :alt="notification.from"
          size="md"
        />
      </UChip>

      <div class="text-sm flex-1">
        <p class="flex items-center justify-between">
          <span class="text-gray-900 dark:text-white font-medium">{{ notification.from }}</span>

          <time
            :datetime="notification.datetime"
            class="text-gray-500 dark:text-gray-400 text-xs"
            v-text="formatTimeAgo(new Date(notification.datetime))"
          />
        </p>
        <p class="text-gray-500 dark:text-gray-400">
          {{ notification.description }}
        </p>
      </div>
    </NuxtLink>
  </UDashboardSlideover>
</template>
