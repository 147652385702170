import type { DashboardSidebarLink } from '#ui-pro/types'
import type { DropdownItem } from '#ui/types'

const moduleToDashboardSidebarLink = (mod: Module): DashboardSidebarLink => ({
  label: mod.name,
  icon: mod.icon,
  to: mod.disabled ? undefined : `/${mod.id}`,
  tooltip: {
    text: mod.name,
    // shortcuts: [],
  },
  disabled: mod.disabled,
})

const moduleToDropdownItem = (mod: Module): DropdownItem => ({
  label: mod.name,
  to: mod.disabled ? undefined : `/${mod.id}`,
  icon: mod.icon,
  disabled: mod.disabled,
})

const sortBy = (key = 'label') => (
  a: Record<string, unknown>,
  b: Record<string, unknown>,
) => a[key].localeCompare(b[key])

export function useNavigation(modules: ComputedRef<Module[]>) {
  const route = useRoute()
  const router = useRouter()
  const { navPageFromPath } = useContentHelpers()
  const { user } = useUserSession()

  const { data: docsNav } = useAsyncData('navigation:docs', () => fetchContentNavigation(), {
    default: () => [],
  })

  const docsNavLinks = computed(() => {
    return mapContentNavigation(navPageFromPath('/docs', docsNav.value)?.children || [])
  })

  const $tasksOfUser = useAsyncData<Task[]>('tasksOfUser', async () => {
    const tasks = await useDB().query.tasks.findMany({
      where: eq(tables.tasks.userId, user.value.id!),
    })
    return tasks
  })

  const $notificationsOfUser = useAsyncData<Notification[]>('notificationsOfUser', async () => {
    const notifications = await useDB().query.notifications.findMany({
      where: eq(tables.notifications.userId, user.value.id!),
    })
    return notifications
  })

  const moduleId = computed({
    get: () => route.path.split('/')[1] ?? 'admin',
    set: (value: string) => router.push(`/${value}`),
  })

  const CORE = ['admin', 'docs']
  const coreModules = computed<Module[]>(() => modules.value.filter(mod => CORE.includes(mod.id)))
  const otherModules = computed<Module[]>(() => modules.value.filter(mod => !CORE.includes(mod.id)))

  const activeModule = computed(() => modules.value.find(mod => mod.id === moduleId.value))

  const LINKS = computed<Record<string, DashboardSidebarLink[]>>(() => ({
    management: [
      {
        label: 'Cuentas',
        icon: 'i-mdi-office-building',
        to: '/management/accounts',
        tooltip: { text: 'Cuentas' },
      },
    ],
    purchases: [
      {
        label: 'Consultas',
        icon: 'i-mdi-message-text',
        to: '/purchases/inquiries',
        tooltip: { text: 'Consultas' },
      },
      {
        label: 'Proveedores',
        icon: 'i-mdi-office-building',
        to: '/purchases/suppliers',
        tooltip: { text: 'Proveedores' },
      },
      {
        label: 'Contactos',
        icon: 'i-mdi-account-group',
        to: '/purchases/contacts',
        tooltip: { text: 'Contactos' },
      },
    ],
    sales: [
      {
        label: 'Negocios',
        icon: 'i-mdi-tag',
        to: '/sales/deals',
        tooltip: { text: 'Negocios' },
      },
      {
        label: 'Actividades',
        icon: 'i-mdi-radiobox-marked',
        to: '/sales/activities',
        tooltip: { text: 'Actividades' },
      },
      {
        label: 'Consultas',
        icon: 'i-mdi-message-text',
        to: '/sales/inquiries',
        tooltip: { text: 'Consultas' },
      },
      {
        label: 'Clientes',
        icon: 'i-mdi-office-building',
        to: '/sales/customers',
        tooltip: { text: 'Clientes' },
      },
      {
        label: 'Contactos',
        icon: 'i-mdi-account-group',
        to: '/sales/contacts',
        tooltip: { text: 'Contactos' },
      },
      {
        label: 'Campañas',
        icon: 'i-mdi-bullhorn',
        to: '/sales/campaigns',
        tooltip: { text: 'Campañas' },
      },
      {
        label: 'Documentos',
        icon: 'i-mdi-file-document',
        children: [
          {
            label: 'Cotizaciones',
            // icon: 'i-mdi-file-document-edit',
            to: '/sales/quotes',
            tooltip: { text: 'Cotizaciones' },
          },
          {
            label: 'Ordenes de Compra',
            // icon: 'i-mdi-cart',
            to: '/sales/purchaseorders',
            tooltip: { text: 'Ordenes de Compra' },
          },
          {
            label: 'Ordenes de Trabajo',
            // icon: 'i-mdi-factory',
            // to: '/sales/workorders',
            tooltip: { text: 'Ordenes de Trabajo' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Documentos' },
      },
    ],
    content: [
      {
        label: 'Categorias',
        icon: 'i-mdi-shape',
        to: '/content/categories',
        tooltip: { text: 'Categorias' },
      },
      {
        label: 'Productos',
        icon: 'i-mdi-package-variant-closed',
        to: '/content/products',
        tooltip: { text: 'Productos' },
      },
      {
        label: 'Aplicaciones',
        icon: 'i-mdi-apps',
        to: '/content/applications',
        tooltip: { text: 'Aplicaciones' },
      },
      {
        label: 'Industrias',
        icon: 'i-mdi-factory',
        to: '/content/industries',
        tooltip: { text: 'Industrias' },
      },
    ],
    finance: [
      {
        label: 'CFDIs',
        icon: 'i-mdi-file-document',
        children: [
          {
            label: 'Carga CFDIs',
            to: '/finance/upload',
            tooltip: { text: 'Carga CFDIs' },
          },
          {
            label: 'Ingresos',
            // to: '/finance/incomes',
            tooltip: { text: 'Ingresos' },
            disabled: true,
          },
          {
            label: 'Egresos',
            // to: '/finance/expenses',
            tooltip: { text: 'Egresos' },
            disabled: true,
          },
          {
            label: 'Nómina',
            // to: '/finance/payroll',
            tooltip: { text: 'Nómina' },
            disabled: true,
          },
          {
            label: 'Cartas Porte',
            // to: '/finance/waybills',
            tooltip: { text: 'Cartas porte' },
            disabled: true,
          },
          {
            label: 'Información de Pagos',
            // to: '/finance/informations',
            tooltip: { text: 'Información de pagos' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'CFDIs' },
      },
      {
        label: 'Tesorería',
        icon: 'i-mdi-cash-register',
        children: [
          {
            label: 'Flujo de Caja',
            tooltip: { text: 'Gestión del flujo de caja' },
            disabled: true,
          },
          {
            label: 'Conciliaciones',
            tooltip: { text: 'Conciliación de conciliaciones bancarios' },
            disabled: true,
          },
          {
            label: 'Cuentas por cobrar',
            tooltip: { text: 'Administración de cuentas por cobrar y pagos programados' },
            disabled: true,
          },
          {
            label: 'Cuentas por pagar',
            tooltip: { text: 'Configuración de pagos recurrentes' },
            disabled: true,
          },
          {
            label: 'Cheques',
            tooltip: { text: 'Emisión y recepción de cheques' },
            disabled: true,
          },
        ],
        defaultOpen: false,
        tooltip: { text: 'Tesorería' },
      },
      {
        label: 'Bancos',
        icon: 'i-mdi-bank-outline',
        children: [
          {
            label: 'Entidades',
            tooltip: { text: 'Gestión de entidades bancarias' },
            disabled: true,
          },
          {
            label: 'Cuentas',
            tooltip: { text: 'Gestión de cuentas bancarias' },
            disabled: true,
          },
          {
            label: 'Movimientos',
            tooltip: { text: 'Gestión de moviemientos bancarias' },
            disabled: true,
          },
          {
            label: 'Préstamos',
            tooltip: { text: 'Gestión de préstamos y créditos bancarios' },
            disabled: true,
          },
          {
            label: 'Créditos',
            tooltip: { text: 'Gestión de préstamos y créditos bancarios' },
            disabled: true,
          },

        ],
        defaultOpen: false,
        tooltip: { text: 'Bancos' },
      },
    ],
    inventory: [
      {
        label: 'Productos',
        icon: 'i-mdi-package-variant-closed',
        children: [
          {
            label: 'Productos',
            // icon: 'i-mdi-package-variant-closed',
            // to: '/inventory/products',
            tooltip: { text: 'Productos' },
          },
          {
            label: 'Items',
            // icon: 'i-mdi-cube',
            // to: '/inventory/items',
            tooltip: { text: 'Items' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: { text: 'Pedidos' },
      },
      {
        label: 'Aplicaciones',
        icon: 'i-mdi-apps',
        to: '/inventory/applications',
        tooltip: { text: 'Aplicaciones' },
      },
      {
        label: 'Industrias',
        icon: 'i-mdi-factory',
        to: '/inventory/industries',
        tooltip: { text: 'Industrias' },
      },
    ],
    docs: docsNavLinks.value,
    admin: [
      {
        label: 'Autenticación',
        icon: 'i-mdi-security',
        children: [
          {
            label: 'Usuarios',
            to: '/admin/auth/users',
            tooltip: { text: 'Usuarios' },
          },
          {
            label: 'Roles',
            to: '/admin/auth/roles',
            tooltip: { text: 'Roles' },
          },
        ],
        defaultOpen: true,
        tooltip: {
          text: 'Autenticación',
        },
      },
      {
        label: 'Almacenamiento',
        icon: 'i-mdi-content-save',
        children: [
          {
            label: 'DB',
            to: '/admin/storage/db',
            tooltip: { text: 'DB' },
          },
          {
            label: 'KV',
            to: '/admin/storage/kv',
            tooltip: { text: 'KV' },
          },
          {
            label: 'Blob',
            to: '/admin/storage/blob',
            tooltip: { text: 'Blob' },
          },
        ],
        defaultOpen: true,
        tooltip: {
          text: 'Almacenamiento',
        },
      },
      {
        label: 'Servidor',
        icon: 'i-mdi-server',
        // for inspiration on what is worth exposing see https://nitro.unjs.io/guide
        children: [
          {
            label: 'API',
            to: '/admin/server/api',
            tooltip: { text: 'API' },
          },
          {
            label: 'Tareas',
            // to: '/admin/server/tasks',
            tooltip: { text: 'Tareas' },
            disabled: true,
          },
        ],
        defaultOpen: true,
        tooltip: {
          text: 'Servidor',
        },
      },
    ],
  }))

  const links = computed<DashboardSidebarLink[][]>(() => {
    if (moduleId.value in LINKS.value) return [LINKS.value[moduleId.value]]
    return [
      otherModules.value.map(moduleToDashboardSidebarLink), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDashboardSidebarLink),
    ]
  })

  const items = computed<DropdownItem[][]>(() => {
    return [
      otherModules.value.map(moduleToDropdownItem), // .sort(sortBy('label')),
      coreModules.value.map(moduleToDropdownItem),
    ]
  })

  const footerLinks = computed<DashboardSidebarLink[]>(() => [
    {
      label: 'Tareas',
      icon: 'i-mdi-checkbox-marked-outline',
      to: '/tasks',
      tooltip: {
        text: 'Tareas',
        shortcuts: ['G', 'T'],
      },
      badge: $tasksOfUser.data.value?.length,
    },
    {
      label: 'Notificaciones',
      icon: 'i-mdi-bell',
      to: '/notifications',
      tooltip: {
        text: 'Notificaciones',
        shortcuts: ['G', 'N'],
      },
      badge: $notificationsOfUser.data.value?.length,
    },
  ])

  const isModuleActive = computed(() => modules.value.some(mod => route.path.startsWith(`/${mod.id}`)))

  return {
    $tasksOfUser,
    $notificationsOfUser,
    moduleId,
    coreModules,
    otherModules,
    activeModule,
    links,
    items,
    footerLinks,
    isModuleActive,
  }
}
